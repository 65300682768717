import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import { useHydrated, useLocalStorage } from 'Hooks'
import storageKeys from 'Constants/storageKeys'
import { SEARCH_VIEW } from 'Constants/ids'
import { SearchViewSwitch, SearchSortOptions } from 'Components/Blocks'
import { Flex } from 'Components/UI'

const SearchResultsPanel = ({
  filters,
  searchTypeSwitch,
  withShowMap,
  onSearchSortChange,
  onSearchViewChange,
}) => {
  const hydrated = useHydrated()

  const [searchView, setSearchView] = useLocalStorage(
    storageKeys.SEARCH_VIEW,
    SEARCH_VIEW.LIST,
  )

  useEffect(() => {
    let view = searchView

    // Reset back to list if map view isn't allowed
    if (!withShowMap && searchView === SEARCH_VIEW.MAP) {
      view = SEARCH_VIEW.LIST
      setSearchView(view)
    }

    onSearchViewChange(view)
  }, [searchView, withShowMap])

  if (!hydrated) {
    return null
  }

  const handleChangeSearchView = view => {
    setSearchView(view)
  }

  return (
    <Flex alignItems="center" mt={['12px', '12px', '12px', 0]} width="100%">
      {searchTypeSwitch && <Flex flex={1}>{searchTypeSwitch}</Flex>}
      <Flex flex={1} justifyContent="flex-end">
        {filters && (
          <Flex flex={searchTypeSwitch ? 0 : 1} mr={3}>
            {filters}
          </Flex>
        )}
        <SearchSortOptions onChange={onSearchSortChange} />
        <SearchViewSwitch
          value={searchView}
          onChange={handleChangeSearchView}
        />
      </Flex>
    </Flex>
  )
}

SearchResultsPanel.defaultProps = {
  filters: null,
  searchTypeSwitch: null,
}

SearchResultsPanel.propTypes = {
  filters: PropTypes.any,
  searchTypeSwitch: PropTypes.any,
  withShowMap: PropTypes.bool.isRequired,
  onSearchSortChange: PropTypes.func.isRequired,
  onSearchViewChange: PropTypes.func.isRequired,
}

export default SearchResultsPanel
