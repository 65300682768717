import React from 'react'

import PropTypes from 'prop-types'

import useHydrated from 'Hooks/useHydrated'

const DesktopSearchFields = ({ children }) => {
  const hydrated = useHydrated()

  if (!hydrated) {
    return null
  }

  return <>{children}</>
}

DesktopSearchFields.defaultProps = {
  children: null,
}

DesktopSearchFields.propTypes = {
  children: PropTypes.any,
}

export default DesktopSearchFields
