import React from 'react'

import PropTypes from 'prop-types'

import useHydrated from 'Hooks/useHydrated'

import { SearchHolder } from './styles'

const SearchFields = ({ children }) => {
  const hydrated = useHydrated()

  if (!hydrated) {
    return null
  }

  return <SearchHolder>{children}</SearchHolder>
}

SearchFields.defaultProps = {
  children: null,
}

SearchFields.propTypes = {
  children: PropTypes.any,
}

export default SearchFields
