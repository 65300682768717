import React from 'react'

import PropTypes from 'prop-types'

import { useBaseTheme, useHydrated } from 'Hooks'

import { LeftSide } from './styles'

const DesktopLeftSide = ({ children }) => {
  const hydrated = useHydrated()
  const { isLargeScreen } = useBaseTheme()

  if (!hydrated || !isLargeScreen) {
    return null
  }

  return <LeftSide>{children}</LeftSide>
}

DesktopLeftSide.defaultProps = {
  children: null,
}

DesktopLeftSide.propTypes = {
  children: PropTypes.any,
}

export default DesktopLeftSide
