import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  loadSuggestions,
  searchMerchants,
  searchServices,
  loadNearMerchants,
  loadCategories,
  loadCategorySuggestions,
  loadFeaturedCategories,
  setCategoryValue,
  setSearchValue,
  setSearchDate,
  loadServicesAvailability,
  loadServicesNextAvailableDate,
} from 'Store/Actions/marketplace'

import {
  getSearchResults,
  getSearchError,
  getIsSearchLoading,
  getFeaturedCategories,
  getFeaturedCategoriesLoading,
  getFeaturedLocations,
  getFeaturedLocationsLoading,
  getCategoryValue,
  getSearchValue,
  getSearchDate,
  getServicesAvailability,
  getCategories,
  getLoadingCategories,
  getServicesNextAvailableDate,
} from 'Store/Selectors/marketplace'

import Search from './Search'

const selector = createStructuredSelector({
  searchResults: getSearchResults,
  searchError: getSearchError,
  isSearchLoading: getIsSearchLoading,
  featuredCategories: getFeaturedCategories,
  featuredLocations: getFeaturedLocations,
  isFeaturedCategoriesLoading: getFeaturedCategoriesLoading,
  isFeaturedLocationsLoading: getFeaturedLocationsLoading,
  categoryValue: getCategoryValue,
  searchValue: getSearchValue,
  searchDate: getSearchDate,
  servicesAvailability: getServicesAvailability,
  servicesNextAvailableDate: getServicesNextAvailableDate,
  categories: getCategories,
  isLoadingCategories: getLoadingCategories,
})

const actions = {
  onLoadSuggestions: loadSuggestions,
  onSearchMerchants: searchMerchants,
  onSearchServices: searchServices,
  onLoadNearMerchants: loadNearMerchants,
  onLoadCategories: loadCategories,
  onLoadCategorySuggestions: loadCategorySuggestions,
  onLoadFeaturedCategories: loadFeaturedCategories,
  onLoadServicesAvailability: loadServicesAvailability,
  onLoadServicesNextAvailableDate: loadServicesNextAvailableDate,
  onSetCategoryValue: setCategoryValue,
  onSetSearchValue: setSearchValue,
  onSetSearchDate: setSearchDate,
}

export default connect(selector, actions)(Search)
