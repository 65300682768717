export const handleGeoError = error => {
  switch (error.code) {
    case error.PERMISSION_DENIED: {
      return 'User denied the request for Geolocation.'
    }
    case error.POSITION_UNAVAILABLE: {
      return 'Location information is unavailable.'
    }
    case error.TIMEOUT: {
      return 'The request to get user location timed out.'
    }
    case error.UNKNOWN_ERROR: {
      return 'An unknown error occurred.'
    }
    default:
      return null
  }
}

export const geoOptions = {
  timeout: 10000,
}

export const getMapUrl = ({ id, address }) => {
  const encoded = encodeURIComponent(address)
  return `https://www.google.com/maps/search/?api=1&query=${encoded}&query=Google&query_place_id=${id}`
}

export const getDirectionsUrl = ({ id, address }) => {
  const encoded = encodeURIComponent(address)
  return `https://www.google.com/maps/dir/?api=1&destination=${encoded}&destination_place_id=${id}`
}

export default {}
